<template lang="pug">
div
  .brand-wrapper.brand-template-chooser-v2
    .container-fluid
      wizard-loader(v-if="loader" @loaderComplete="loader = false")
      template(v-else)
        SideBySide
          template(#leftColumn)
            Navigation.mt-6(:items="menuItems" @click="onMenuClick")
            .search-bar-input-wrapper.ml-auto.mt-6.mb-2
              om-input#template-search-input.search-bar-input(
                type="text"
                :placeholder="$t('search')"
                v-model.trim="searchTerm"
                @enter="doSearch"
              )
                template(#suffix)
                  om-button.search-bar-button(title="Search" ghost iconOnly @click.stop="doSearch")
                    template(#icon)
                      UilSearch(size="24")
            .filters(v-if="isOnHome" :class="{ 'filters-fixed': fixedFilters }" ref="filters")
              TemplateFilter(
                newChooser
                :templateTypes="templateTypes"
                :templateGoals="templateGoals"
                :templateContents="templateContents"
                :selectedTypes="filter.types"
                :selectedGoals="filter.goals"
                :selectedContents="filter.contents"
                @typesChange="onTypesChange"
                @goalsChange="onGoalsChange"
                @contentsChange="onContentsChange"
                @track="reportLeftMenuClick"
              )
          .template-chooser-page-content
            transition-group(name="fade" mode="out-in")
              .search-bar-heading.row.align-items-start.justify-content-between(
                ref="heading"
                key="pageTitle"
              )
                .col-auto(:class="{ 'col-6': isLangChooserAndBelowSize }")
                  om-heading(h1) {{ $t(title) }}
                  om-body-text.template-chooser-subtitle.mt-2(bt400md v-html="$t(subTitle)")
                .row.col
                  .col-12.col-lg.d-flex.justify-content-end.my-2
                    language-chooser(@change="languageUpdated($event, true)")
              .template-chooser-filter-tags.d-flex.pb-5(
                v-if="(filters.length || search) && isOnHome"
                key="filters"
              )
                om-chip(
                  v-if="search"
                  small
                  color="primary"
                  removable
                  @remove="onSearchRemove"
                  key="search-chip"
                ) {{ $t('templateChooser.search.resultTitle', { term: search }) }}
                om-chip(
                  v-if="filters.length"
                  small
                  color="primary"
                  removable
                  v-for="filter in filters"
                  @remove="removeFilter({ type: filter.type, value: filter.value })"
                  :key="filter.value"
                ) {{ getFilterName(filter) }}
                .template-chooser-filter-clear.cursor-pointer.py-2.px-1.ml-1.text-center.font-size-0--9375(
                  v-if="filters.length || search"
                  @click="onClearFilterClick"
                ) {{ $t('clear') }}
              router-view(
                key="child-view"
                :filter="filter"
                :useCaseMap="useCaseMap"
                :themes="themes"
                :search="search"
                @messageTypeFilter="onTopFilterClick"
                @navigateWithFilterReset="onNavigateWithFilterReset"
                @createCampaign="startFromScratch"
                @track="reportMenuClick"
                @trackActivity="onTrack"
                @refreshData="onRefreshData"
                @filter="onFilterSet"
              )
    template-preview
    new-campaign
    NewCampaignSMSPrompt
    from-scratch-chooser(@start-from-scratch="startFromScratch($event)")
    last-request-date-old
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import { get } from 'lodash-es';
  import SAVE_SEARCH_ACTIVITY from '@/graphql/SaveSearchActivity.gql';
  import { convertObjectToQueryParamsString } from '@/util';
  import WizardLoader from '@/components/Wizard/WizardLoader.vue';
  import LanguageChooser from '@/components/TemplateChooser/components/LanguageChooser.vue';
  import { UilSearch } from '@iconscout/vue-unicons';
  import { track } from '@/services/xray';

  export default {
    components: {
      TemplatePreview: () => import('@/components/Modals/TemplatePreview'),
      WizardLoader,
      LanguageChooser,
      NewCampaign: () => import('@/components/Modals/NewCampaign.vue'),
      NewCampaignSMSPrompt: () => import('@/components/Modals/NewCampaignSMSPrompt.vue'),
      FromScratchChooser: () => import('@/components/Modals/FromScratchChooser.vue'),
      LastRequestDateOld: () => import('@/components/Modals/LastRequestDateOld.vue'),
      SideBySide: () => import('@/components/TemplateChooser/layout/SideBySide.vue'),
      Navigation: () => import('@/components/TemplateChooser/components/Navigation.vue'),
      TemplateFilter: () => import('@/components/Template/Filter.vue'),
      UilSearch,
    },

    beforeRouteUpdate(to, from, next) {
      // the user changed the filters
      if (to.name !== from.name) {
        this.fixedFilters = false;
        this.scrollToTop();
        this.setFilterPosition();
      }
      next();
    },

    beforeRouteEnter(to, from, next) {
      next((vm) => {
        if (from.name !== 'templates') {
          vm.scrollToTop();
        }
        const loaderParam = to?.params?.recLoader ?? true;
        const showWizardLoader = from.path.includes('onboarding') && loaderParam;
        if (showWizardLoader) {
          vm.loader = true;
        } else {
          vm.showAdminLoader(true);
        }
        vm.initUseCasesStore();
        vm.fetchThemes();
        vm.fetchThemeFamilies();
        vm.fetchResellerTemplates();
        vm.fetchSeasonalBanners();
        vm.fetchCustomTemplateId();
        vm.fetchFlyerTemplateId();
        vm.fetchPossibleFilterValues();
      });
    },
    data: () => ({
      templateTypes: [],
      templateGoals: [],
      templateContents: [],
      activeMenu: null,
      queryParameters: {},
      loader: false,
      language: null,
      fixedFilters: false,
      filterPosition: 0,
      searchTerm: null,
      windowWidth: window.innerWidth,
      filterUnwatch: null,
    }),
    computed: {
      ...mapState(['preferredTemplateLanguage', 'accountType', 'whiteLabelSettings']),
      ...mapState({ adminLoaderState: 'showAdminLoader' }),
      ...mapGetters([
        'isRealTemplateAuthor',
        'onboardingFinished',
        'isSubUser',
        'isAffiliate',
        'hasAccountFeature',
      ]),
      ...mapState('templateChooser', [
        'themes',
        'customTemplateId',
        'flyerTemplateId',
        'baseThemeKitIds',
        'filterValues',
        'filter',
        'search',
        'resellerTemplates',
      ]),
      ...mapGetters('templateChooser', ['loading', 'filters']),
      ...mapGetters('useCase', ['useCaseNameByIdAndLocale', 'useCaseDescriptionByIdAndLocale']),
      ...mapState('useCase', ['useCaseMap', 'useCaseLoading']),
      ...mapState('campaignCreation', { currentCampaignCreationStep: 'step' }),
      ...mapGetters('campaignCreation', ['previousCampaignCreationStep']),
      isSeasonal() {
        return this.$route.name.includes('seasonal');
      },
      isUseCasePage() {
        return this.$route.name.includes('chooser-use-case');
      },
      isThemesPage() {
        return this.$route.name.includes('themes');
      },
      isOtherListing() {
        return this.$route.name === 'chooser-other-listing';
      },
      getIdFromSlug() {
        const urlParts = this.$route.params?.slug?.split('-') || [];
        return urlParts.reverse()[0] ?? null;
      },
      title() {
        if (this.isOtherListing && this.$route.params.type === 'goals') {
          return this.$t(`tactic.goal.${this.$route.params.slug}`);
        }
        if (this.isSeasonal && this.getIdFromSlug) {
          return `templateFilter.categories.${this.getIdFromSlug}`;
        }

        if (this.isUseCasePage && this.getIdFromSlug) {
          return this.useCaseNameByIdAndLocale(this.getIdFromSlug, this.$i18n.locale);
        }

        if (this.isThemesPage) {
          return this.themesPageTitle;
        }

        return this.activeMenu?.key !== 'home'
          ? this.activeMenu?.name ?? 'templateChooser.title'
          : 'templateChooser.title';
      },
      subTitle() {
        if (this.isUseCasePage) {
          return this.useCaseDescriptionByIdAndLocale(this.getIdFromSlug, this.$i18n.locale);
        }

        return null;
      },
      isOnHome() {
        if (this.isUseCasePage || this.isSeasonal || this.isThemesPage || this.isOtherListing) {
          return false;
        }

        return !this.activeMenu || this.activeMenu?.key === 'home';
      },
      baseThemes() {
        const base = this.themes?.base || [];
        return base.filter(({ _id }) => !!_id);
      },
      query() {
        return Object.fromEntries(
          Object.entries(this.queryParameters).map(([key, value]) => [
            key,
            typeof value === 'object' || Array.isArray(value) ? JSON.stringify(value) : value,
          ]),
        );
      },
      isLangChooserAndBelowSize() {
        return this.windowWidth <= 1450;
      },
      brandName() {
        const brand = this.whiteLabelSettings?.brandName;

        return !brand || brand === 'OptiMonk'
          ? this.$t('templateManager.tabs.partnerFeatured')
          : brand;
      },
      menuItems() {
        const query = { ...this.query };
        const menus = [
          {
            key: 'home',
            name: 'templateChooser.home',
            icon: 'estate',
            to: { name: 'templates', query },
          },
          {
            key: 'themes-collection',
            name: 'themes',
            icon: 'paint-tool',
            to: { name: 'themes-collection', query },
            children: [
              {
                key: 'branded-themes',
                name: 'templateChooser.brandedThemes',
                to: { name: 'themes-collection', query },
              },
              {
                key: 'seasonal-collection',
                name: 'templateChooser.seasonalTemplates',
                to: { name: 'seasonal-collection', query },
              },
              {
                key: 'your-themes-collection',
                name: 'customTheme.chooser.block.title',
                to: { name: 'your-themes-collection', query },
              },
            ],
          },
          {
            key: 'chooser-use-cases',
            name: 'templateChooser.useCases',
            icon: 'archive',
            to: { name: 'chooser-use-cases', query },
          },
          {
            key: 'new-empty',
            name: this.$t('startFromScratch'),
            icon: 'vector-square',
          },
        ];

        if (this.accountType === 'sub' && this.resellerTemplates?.length) {
          menus.splice(3, 0, {
            key: 'reseller-templates',
            name: this.$t('resellerTemplates', { brand: this.brandName }),
            icon: 'star',
            to: { name: 'reseller-templates', query },
          });
        }

        return menus;
      },
      themesPageTitle() {
        if (this.getIdFromSlug) {
          const theme = this.$route.params.slug.split('-').slice(0, -1).join(' ');
          return this.$t('templateChooser.themeWithName', {
            theme,
          });
        }

        return this.$t('templateChooser.themes');
      },
      isPageLoading() {
        return this.loading || this.useCaseLoading;
      },
    },
    watch: {
      isPageLoading(loading) {
        if (!loading && this.adminLoaderState) {
          this.showAdminLoader(false);
        }
      },
      '$route.fullPath': function () {
        this.initActiveMenu();
      },
      filterValues: {
        handler() {
          this.initiateFilters();
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll, true);
      window.addEventListener('popstate', this.onPopState, true);
      this.filterUnwatch = this.$watch('filter', this.debouncedFilterWatch, { deep: true });
      this.languageUpdated();
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.onScroll, true);
      window.removeEventListener('popstate', this.onPopState, true);
      this.filterUnwatch();
    },
    async created() {
      await Promise.all([...(this.onboardingFinished ? [] : [this.finishOnboarding()])]);

      await this.fetchFilteredTemplates();
      await this.fetchSeasonal();

      this.initiateFilters();
      this.initActiveMenu();
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      ...mapActions(['finishOnboarding']),
      ...mapActions('templateChooser', [
        'fetchThemes',
        'fetchCustomTemplateId',
        'fetchFlyerTemplateId',
        'fetchPossibleFilterValues',
        'fetchFilteredTemplates',
        'fetchResellerTemplates',
        'fetchThemeFamilies',
        'fetchSeasonalBanners',
        'fetchSeasonal',
        'addFilter',
        'addFilters',
        'removeFilter',
        'resetFilters',
      ]),
      ...mapMutations('templateChooser', ['SET_SEARCH']),
      ...mapActions('useCase', { initUseCasesStore: 'init' }),
      ...mapMutations('campaignCreation', ['setCurrentStep']),
      debouncedFilterWatch() {
        this.addFiltersToQuery();
      },
      onRefreshData() {
        this.fetchThemes();
      },
      onMenuClick(path) {
        if (path === 'new-empty') return this.createEmpty();
        this.clearAllFilters();
        if (path === '0') {
          this.$router.push({ name: 'templates' });
        }
        const realPath = path.replace('.', '.children.');
        this.activeMenu = get(this.menuItems, realPath, this.activeMenu);
        this.reportMenuClick(this.activeMenu);
      },
      onScroll() {
        if (this.isLeftColumnHigherThanContent()) {
          return this.handleFixedFilterWithHigherContent();
        }

        this.setNavigationMinHeight(null);

        const element = this.$refs.filters;
        const heading = this.$refs.heading;

        const { height = 0 } = heading?.getBoundingClientRect?.() ?? {};
        if (this.fixedFilters === false && this.filterPosition < document.body.scrollTop) {
          this.fixedFilters = true;
          this.filterPosition = element.offsetTop - height;
          element.style.top = 0;
        }

        if (this.fixedFilters === true && this.filterPosition > document.body.scrollTop) {
          element.scrollTop = 0;
          this.fixedFilters = false;
          this.filterPosition = element.offsetTop - height;
        }
      },
      setFilterPosition() {
        const element = this.$refs.filters;
        if (!element) return;
        const heading = this.$refs.heading;
        const { height = 0 } = heading?.getBoundingClientRect?.() ?? {};
        this.filterPosition = height ? element.offsetTop - height : element.offsetTop;
      },
      navigateWithFilter(to) {
        const params = this.getParams();
        if (Object.keys(params).length) {
          to.query = { ...params };
          this.$router.push(to);
        } else {
          this.onNavigateWithFilterReset(to);
        }
      },
      getParams() {
        const params = {};
        Object.keys(this.filter).forEach((k) => {
          if (this.filter[k].length) {
            params[k] = this.filter[k];
          }
        });

        return params;
      },
      addFiltersToQuery() {
        const params = this.getParams();
        if (this.language !== 'en') {
          params.lang = this.language;
        }
        this.queryParameters = params;

        const path = this.$route.path + convertObjectToQueryParamsString(params);
        if (this.$route.fullPath !== path) {
          window.history.replaceState({ ...history.state }, '', path);
        }
      },

      scrollToTop() {
        const { body } = document;
        this.$nextTick(() => body.scrollTo(0, 0));
      },

      async addOrRemoveFilter(type, newValues, oldValues) {
        const newValueLength = newValues.length;
        if (newValueLength > oldValues.length) {
          await this.addFilter({ type, value: newValues[newValueLength - 1] });
        } else {
          const difference = oldValues.filter((value) => !newValues.includes(value));

          await this.removeFilter({ type, value: difference[0] });
        }

        this.$bus.$emit('filter-changed');
        await this.fetchFilteredTemplates();
        await this.fetchSeasonal();
      },
      clearAllFilters() {
        this.resetFilters();
        this.resetSearch();
      },
      getFilterName(filter) {
        if (filter.type === 'goals') {
          return this.$t(`tactic.goal.${filter.value}`);
        }
        return this.$tc(`templateFilter.${filter.type}.${filter.value}`, 1);
      },
      createEmpty() {
        this.$modal.show('from-scratch-chooser');
      },
      startFromScratch(type) {
        this.onTrack({ component: 'Start from scratch', setting: type });
        const templateId = type === 'empty' ? this.customTemplateId : this.flyerTemplateId;
        this.$modal.show('name-campaign', { templateId });
      },
      initiateFilters() {
        this.initiatePossibleFilterValues();

        const currentSearch = new URLSearchParams(window.location.search);
        const filterKeys = Object.keys(this.filter);
        currentSearch.forEach((value, key) => {
          if (filterKeys.includes(key)) {
            try {
              const parsed = JSON.parse(value);
              parsed.forEach((value) => this.addFilter({ type: key, value }));
            } catch (e) {
              console.error('Failed to parse filters', e);
            }
          }
        });
      },
      onNavigateWithFilterReset(to) {
        this.clearAllFilters();
        this.$router.push(to);
      },
      onNavigateWithFilter(to) {
        this.navigateWithFilter(to);
      },
      onPopState() {
        if (!this.currentCampaignCreationStep) {
          this.clearAllFilters();
          this.initiateFilters();
        }
        this.handleCampaignCreationNavigation();
      },
      getAllMenuItems() {
        return this.menuItems
          .map((item) => {
            if (!item.to) return null;
            if (!item.children) return item;

            return [{ ...item, children: undefined }, ...item.children];
          })
          .filter((v) => !!v)
          .flat();
      },
      findActiveMenu(allMenuItems) {
        return allMenuItems.find(({ to }) => {
          const sameRoute = to?.name === this.$route.name;
          const routeSlug = this.$route.params?.slug;
          return routeSlug ? sameRoute && routeSlug === to?.params?.slug : sameRoute;
        });
      },
      initActiveMenu() {
        const allMenuItems = this.getAllMenuItems();
        const activeMenu = this.findActiveMenu(allMenuItems);
        this.activeMenu = activeMenu || null;
      },
      languageUpdated(lang, showLoader = false) {
        const loaderShowed = showLoader && this.isOnHome && !this.filters.length;
        if (loaderShowed) this.showAdminLoader(true);

        if (this.language !== null) {
          this.language = lang;
          this.debouncedFilterWatch();
        } else {
          this.language = this.preferredTemplateLanguage;
        }

        if (loaderShowed) {
          setTimeout(() => this.showAdminLoader(false), 250);
        }
      },
      initiatePossibleFilterValues() {
        const { goals = [], types = [], contents = [] } = this.filterValues || {};

        const goalsKeyValue = Object.keys(this.$t('tactic.goal')).reduce((acc, key, index) => {
          acc[key] = index;
          return acc;
        }, {});
        this.templateGoals = goals.map((name) => name);
        this.templateGoals.sort((a, b) => goalsKeyValue[a] - goalsKeyValue[b]);
        this.templateTypes = types.map((name) => name).filter((type) => type !== 'fullscreen'); // Interstitial enough
        this.templateContents = contents.map((name) => name);
      },
      async doSearch() {
        if (this.searchTerm?.length) {
          this.$apollo.mutate({
            mutation: SAVE_SEARCH_ACTIVITY,
            variables: { searchTerm: this.searchTerm },
          });
          this.activeMenu = this.menuItems[0];
          if (this.$route.name !== 'templates') {
            this.$router.push(this.menuItems[0].to);
          }
        }
        this.SET_SEARCH(this.searchTerm);
        this.searchTerm = null;
        await this.fetchFilteredTemplates();
        this.$bus.$emit('search-changed');
      },
      resetSearch() {
        this.searchTerm = null;
        this.doSearch();
      },
      getLeftColSize() {
        const leftNavigation = document
          .querySelector('.layout-left-column .navigation-v2')
          ?.getBoundingClientRect();
        const leftFilters = document
          .querySelector('.layout-left-column .filters')
          ?.getBoundingClientRect();

        return leftNavigation?.height + leftFilters?.height;
      },
      isLeftColumnHigherThanContent() {
        const leftColSize = this.getLeftColSize();
        const rightWrapperSize = document
          .querySelector('.template-chooser-page-content span[mode="out-in"]')
          ?.getBoundingClientRect();
        return rightWrapperSize?.height < leftColSize;
      },
      setNavigationMinHeight(height) {
        if (this.$refs.navigation?.$el?.style?.minHeight) {
          this.$refs.navigation.$el.style.minHeight = height ? `${height}px` : null;
        }
      },
      handleFixedFilterWithHigherContent() {
        if (!this.fixedFilters) return;

        const colHeight = this.getLeftColSize();

        this.setNavigationMinHeight(colHeight);
        this.fixedFilters = false;
        document.querySelector('body')?.scrollTo?.({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      },
      reportMenuClick(menu) {
        if (!menu) return;
        const { name } = menu;
        this.reportLeftMenuClick({
          component: this.$te(name) ? this.$t(name, 'en') : name,
        });
      },
      onTrack(event) {
        const fixedLocationEvent = {
          ...event,
          location: 'main',
        };
        this.trackWithHeap(fixedLocationEvent);
      },
      reportLeftMenuClick(event) {
        const fixedLocationEvent = {
          ...event,
          location: 'left_menu',
        };
        this.trackWithHeap(fixedLocationEvent);
      },
      trackWithHeap(properties) {
        track('template_selector_click', properties);
      },
      onClearFilterClick() {
        this.onTrack({ component: 'Filter clear' });
        this.clearAllFilters();
        this.debouncedFilterWatch();
      },
      onSearchRemove() {
        this.onTrack({
          component: 'Search',
          setting: 'remove',
          options: this.searchTerm,
        });
        this.resetSearch();
      },
      async onTopFilterClick(type) {
        if (type === 'gamification') {
          await this.filterToGamification();
          return;
        }
        this.onTypesChange([...this.filter.types, type]);
      },
      onTypesChange(v) {
        this.addOrRemoveFilter('types', v, this.filter.types);
      },
      onGoalsChange(v) {
        this.addOrRemoveFilter('goals', v, this.filter.goals);
      },
      onContentsChange(v) {
        this.addOrRemoveFilter('contents', v, this.filter.contents);
      },
      onFilterSet(filter) {
        const { goal } = filter;

        if (goal) {
          this.onGoalsChange([goal]);
        }
      },
      async filterToGamification() {
        this.addFilters({
          type: 'contents',
          values: ['luckyWheel', 'scratchCard', 'pickAPresent'],
        });
        this.$bus.$emit('filter-changed');
        await this.fetchFilteredTemplates();
        await this.fetchSeasonal();
      },

      handleCampaignCreationNavigation() {
        this.$modal.hide(this.currentCampaignCreationStep);

        if (this.previousCampaignCreationStep) {
          this.$modal.show(this.previousCampaignCreationStep);
          return;
        }

        this.setCurrentStep(null);
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .brand-template-chooser-v2
    padding-top: 0
    padding-bottom: 0
    .template-chooser-header
      display: flex
      justify-content: space-between
    .search-bar
      &-heading
        top: -1px
        background: white
        z-index: 19
        margin: 0 -3.125rem
        padding: 1.25rem 2.5rem
      &-button
        padding: .375rem !important
      &-input
        &-wrapper
          max-width: 25rem
        .input-suffix-slot
          padding: 0 !important
    .template-chooser-search-term
      margin-bottom: 2.5rem
    .om-overlay-center
      overflow: hidden !important
    .layout-side-by-side
      margin-top: 1.25rem
      .layout-right-column
        display: flex
        justify-content: center
        flex-grow: 1
    .template-filter
      padding: 0
      padding-bottom: 6rem
      height: 100vh
      .brand-slide-down-title
        color: $om-gray-800
    .filters
      position: sticky
      &.filters-fixed
        .template-filter
          overflow-y: auto

    .template-chooser
      &-page-content
        max-width: 93.75rem
        width: 100%

      &-filter
        &-tags
          margin-left: -6px

        &-clear
          color: $om-orange-500

      &-subtitle
        color: $om-gray-600
</style>
